import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { withStyles, Typography, Box, Link } from "@material-ui/core";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as d3 from "d3";
import { COLORS } from "../../../theme";
import { TwoColBlock } from "../../../components/sections";
import DownloadButton from "../../paper/components/downloadButton";

const styles = (theme) => ({
  root: {
    // TODO - remove once we have multiple posts
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(20),
    },
    "& .download-button": {
      marginTop: theme.spacing(0.5),
    },
    "& .research-posts": {
      padding: theme.spacing(8, 0),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(10, 0),
      },
    },
  },
  postRow: {
    "&:nth-child(odd)": {
      background: COLORS.paper,
    },
    "& .two-col-block__left": {
      maxWidth: 450,
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: 0,
      [theme.breakpoints.up("md")]: {
        maxWidth: 330,
        marginRight: "unset",
        paddingLeft: "unset",
      },
    },
    "& .two-col-block__right": {
      maxWidth: "fit-content",
      marginRight: "auto",
      marginLeft: "auto",
      [theme.breakpoints.up("md")]: {
        marginLeft: "unset",
      },
    },
    "& h3": {
      fontSize: "23px",
      [theme.breakpoints.up("md")]: {
        marginLeft: "unset",
      },
    },
    "& .MuiGrid-container": {
      maxWidth: "1030px",
    },
    // NOTE: we set the max-width on the inner div (instead of block__right) to avoid odd
    // layout behavior around the md breakpoint (the two cols of the TwoColBlock were
    // moving to the same line before at a narrower width than the breakpoint)
    "& .two-col-block__right > .MuiBox-root": {
      maxWidth: 450,
    },
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
});

const PostList = ({ classes, ...props }) => {
  // TODO JJ - could avoid using useStaticQuery by instead passing down this data
  // from the ResearchIndexQuery
  const data = useStaticQuery(graphql`
    query PostListQuery {
      allMdx(
        filter: { frontmatter: { meta: { isBlogPost: { eq: true } } } }
        sort: { order: DESC, fields: frontmatter___meta___date }
      ) {
        nodes {
          frontmatter {
            draft
            path
            meta {
              author
              date
              description
              filePath
              keywords
              title
              image {
                childImageSharp {
                  gatsbyImageData(
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                    width: 270
                    height: 228
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const posts = data?.allMdx?.nodes;

  const getPostRow = (post) => {
    const { image, title, author, date, filePath, description } =
      post.frontmatter.meta;
    const left = <GatsbyImage image={getImage(image)} />;

    const right = (
      <Box>
        <Typography color="secondary" variant="body2">
          {author} • {d3.timeFormat("%B %e, %Y")(new Date(date))}
        </Typography>
        <Link href={post.frontmatter.path}>
          <Typography className={classes.title} variant="h5" component="h3">
            {title}
          </Typography>
        </Link>
        <Typography className={"description"} variant="body1">
          {description}
        </Typography>
        <DownloadButton path={filePath} />
      </Box>
    );

    return (
      <TwoColBlock
        className={[classes.postRow, "research-posts"]}
        right={right}
        left={left}
      ></TwoColBlock>
    );
  };

  return <Box className={classes.root}>{posts.map(getPostRow)}</Box>;
};

export default withStyles(styles)(PostList);
