import React from "react";
import { Block } from "@hyperobjekt/material-ui-website";
import { Box, withStyles } from "@material-ui/core";
import { Button } from "gatsby-theme-material-ui";
import { StaticImage } from "gatsby-plugin-image";
import { FONTS } from "../theme";
import Typography from "../components/typography";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(15, 0, 15),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(12, 0, 10),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(12, 0, 15),
    },
  },
  container: {
    justifyContent: "flex-start",
    gap: theme.typography.pxToRem(90),
    [theme.breakpoints.up("md")]: {
      gap: theme.typography.pxToRem(88),
    },
  },
  btnSeeFilm: {
    marginRight: "auto",
    marginTop: "16px",
    border: "2px solid green",
  },
  title: {
    position: "relative",
    ...FONTS.KNOCKOUT.FullMiddleweight,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(60),
    lineHeight: "60px" /* 75% */,
    letterSpacing: "3.2px",
    marginBottom: `0.25em`,
    "&:after": {
      content: "''",
      position: "absolute",
      bottom: `-1em`,
      left: 0,
      width: `1.75em`,
      height: `0.5em`,
      backgroundImage: `url(/images/underline.svg)`,
      backgroundSize: `contain`,
      backgroundRepeat: `no-repeat`,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(80),
    },
  },
  intro: {
    display: "flex",
    flexDirection: "column",
    gap: 71,
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",

    "& > p + p": {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      "& > p": {
        maxWidth: "30em",
      },
    },
  },
  video: {
    aspectRatio: 16 / 9,
    width: "100%",
    flex: "0 0 60%",
    backgroundColor: "transparent",
  },
  caption: {
    marginTop: theme.spacing(1),
    fontStyle: "italic",
  },
  description: {
    padding: "40px 0",
    borderTop: `6px solid ${theme.palette.primary.main}`,
    borderBottom: `6px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontFamily: FONTS.KNOCKOUT.Middleweight.fontFamily,
    fontSize: theme.typography.pxToRem(40),
    lineHeight: "50px",
    letterSpacing: "1.2px",
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(60),
      lineHeight: "70px",
    },
  },
  festivals: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: 48,

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      gap: 132,
    },
  },
  festivalEntries: {
    color: theme.palette.primary.main,
    ...FONTS.KNOCKOUT.Lightweight,
    fontSize: "30px",
    lineHeight: "39px",
    letterSpacing: "1.2px",
  },
  images: {
    marginTop: 26,
    display: "flex",
    gap: 40,
    flexWrap: "wrap",
  },
  entry: {
    width: 88,
    height: 88,
  },
  eyebrow: {
    color: theme.palette.primary.main,
    ...FONTS.KNOCKOUT.Lightweight,
    fontSize: "20px",
    lineHeight: "130%" /* 26px */,
    letterSpacing: "2px",
    textTransform: "uppercase",
  },
});

const DocumentaryFilm = ({ classes, ...props }) => {
  const { root, container } = classes;

  return (
    <Block bgcolor="background.paper" classes={{ root, container }} {...props}>
      <Box>
        <Typography className={classes.eyebrow}>Documentary Film</Typography>
        <h2 className={classes.title}>Shame On You!</h2>
      </Box>
      <Box className={classes.intro}>
        <Box className={classes.video}>
          <iframe
            title="Shame On You!"
            src="https://player.vimeo.com/video/872013158?h=f09ed6e8ab"
            style={{
              border: 0,
              width: "100%",
              height: "100%",
            }}
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
          <Typography className={classes.caption}>Official Trailer</Typography>
        </Box>

        <Box className={classes.content}>
          <Box>
            <Typography>
              Shame On You! is a short animated documentary that plunges into
              the visual data presented at the Debt Collection Lab. In
              recreating several personal stories of people trapped in debt
              collection lawsuits, the film shows that it is because of hidden
              shame about debt that their stories are untold, which helps
              explain why 90% of the cases in the data are either unrepresented
              in courts or unopposed. The film exposes how the debt collection
              industry is enabled by shame as well as fear of the judicial
              system.
            </Typography>
            {/*
            Enable once we have a URL to the full film
            <Button
              style={{
                marginRight: "auto",
                marginTop: 28,
              }}
              variant="contained"
              color="primary"
            >
              See the full film
            </Button> */}
          </Box>
        </Box>
      </Box>
      <Box>
        <Box className={classes.description}>
          The film exposes how the debt collection industry is enabled by shame
          as well as fear of the judicial system.
        </Box>
      </Box>
      <StaticImage src={"./images/documentary-banner.png"} alt="" />
      <Box className={classes.festivals}>
        <Box className={classes.content}>
          <Typography>
            The film premiered at its home campus of Princeton University in
            December 2023. Shame On You! was produced, directed, and edited by
            Jeffrey Himpele in the Anthropology VizE Lab at Princeton. It is
            based on extensive interviews and a script by investigative
            journalist Ann Neumann and features voice actresses Vivia Font, Dana
            Green, and Melody Butiu. The film was animated by Michael Arthur.
            Debt Collection Lab founder Frederick F. Wherry is the film’s
            executive director.
          </Typography>
          <Typography>
            The entire film will be freely available here soon!
          </Typography>
        </Box>
        <Box className={classes.festivalEntries}>
          <Box>Festival Entries</Box>
          <Box className={classes.images}>
            <StaticImage
              className={classes.entry}
              src={"./images/spark.jpg"}
              alt="Spark Computer Graphics Society"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/LAAF.jpg"}
              alt="Los Angeles Animation Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/DC-festival.jpg"}
              alt="Washington DC International Cinema Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/documentaries-without-borders.jpg"}
              alt="Documentaries Without Borders International Film Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/best-shorts-competition.jpg"}
              alt="Best Shorts Competition"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/ethnografilm.jpg"}
              alt="Ethnografilm"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/impact-docs-awards.jpg"}
              alt="Best Shorts Competition"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/spotlight-on-academics.jpg"}
              alt="Spotlight on Academics Film Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/montreal-logo.jpeg"}
              alt="Montreal Independent Film Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/NJIFF.jpg"}
              alt="New Jersey International Film Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/boston-indie.jpg"}
              alt="Boston Indie Film Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/chicago-logo.jpg"}
              alt="Chicago Indie Film Awards"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/NYICF.jpg"}
              alt="New York Independent Cinema Awards"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/seattle-logo.jpg"}
              alt="Seattle Film Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/NJDFF.jpg"}
              alt="New Jersey Documentary Film Festival"
            />
            <StaticImage
              className={classes.entry}
              src={"./images/sva-logo.jpg"}
              alt="Society for Visual Anthropology"
            />
          </Box>
        </Box>
      </Box>
    </Block>
  );
};

export default withStyles(styles)(DocumentaryFilm);
