import { ResearchHero, PostList } from "research";
import TwoColBlock from "components/sections/two-col-block.js";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import * as React from 'react';
export default {
  ResearchHero,
  PostList,
  TwoColBlock,
  getImage,
  GatsbyImage,
  React
};