import React, { useMemo, useState } from "react";
import clsx from "clsx";
import {
  TextField,
  Button,
  Box,
  Typography,
  Icon,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { cloneDeep } from "lodash";
import { withStyles } from "@material-ui/styles";
import { graphql, useStaticQuery } from "gatsby";
import { slugify } from "../utils";
import { NO_STATES_PAGE } from "../../scripts/consts";
import { formatDateRangeShort, parseMonthYear } from "../lawsuit-tracker/utils";

const styles = (theme) => ({
  root: {
    width: "100%",
    "& img": {
      paddingLeft: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },
  },
  tableWrapper: {
    overflowX: "auto",
    "& MuiTable-root": {
      minWidth: 450,
    },
  },
  siteCell: {
    "&.MuiTableCell-head": {
      paddingLeft: 42,
    },
    [theme.breakpoints.up("md")]: {
      width: 280,
    },
  },
  dateCell: {
    width: 140,
  },
  downloadCell: {
    width: 140,
  },
  stateRow: {
    "& $siteCell": {
      "&.expandable": {
        cursor: "pointer",
      },
      "& .MuiTypography-root": {
        fontWeight: 500,
      },
    },
  },
  childRow: {
    "& $siteCell": {
      "& .MuiTypography-root": {
        marginLeft: 26,
      },
    },
  },
});

const StyledTextField = withStyles((theme) => ({
  root: {
    width: "250px",
    margin: `${theme.spacing(2, 0)} !important`,
    "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
      width: 28,
      height: 28,
    },
    "& input::placeholder": {
      fontSize: "14px",
    },
  },
}))(TextField);

function getFiles() {
  const data = useStaticQuery(graphql`
    query FileDownloadQuery {
      allStates(sort: { fields: name }) {
        nodes {
          name
          earliest_lawsuit
          latest_lawsuit
          counties {
            name
            earliest_lawsuit
            latest_lawsuit
          }
        }
      }
    }
  `);
  return data.allStates.nodes
    .filter((x) => x.name !== "Connecticut")
    .map(({ name, earliest_lawsuit, latest_lawsuit, counties }) => {
      const state = { name, earliest_lawsuit, latest_lawsuit };
      state.no_download = NO_STATES_PAGE[state.name];
      state.path = slugify(state.name);
      state.children = counties
        .map((county) => ({
          ...county,
          path: slugify(`${state.name}-${county.name}`),
        }))
        .sort((a, b) => a.path.localeCompare(b.path));

      return state;
    });
}

function passesFilter(file, filterTerm) {
  // child path contains state name, so will display when state searched for
  return file.path
    .replace(/[^a-z]/g, "")
    .includes(filterTerm.toLowerCase().replace(/[^a-z]/g, ""));
}

function filterFiles(files, filterTerm) {
  if (!filterTerm) return files;
  const result = cloneDeep(files);
  result.forEach((state) => {
    state.children.forEach(
      (child) => (child.hidden = !passesFilter(child, filterTerm))
    );
    state.hidden =
      !passesFilter(state, filterTerm) &&
      state.children.every((child) => child.hidden);
  });
  return result;
}

const DownloadButton = ({ file }) => (
  <Button
    href={`/data/${file.path}.csv`}
    disableRipple
    download
    target="_blank"
  >
    Download
    <img src="/images/download.svg" alt="" />
  </Button>
);

const FileExplorer = ({ classes }) => {
  const files = useMemo(getFiles, []);
  const [filterTerm, setFilterTerm] = useState("");
  const [expanded, setExpanded] = useState({});
  const toggleExpanded = ({ path }) =>
    setExpanded({ ...expanded, [path]: !expanded[path] });

  const filteredFiles = filterFiles(files, filterTerm);
  return (
    <Box className={classes.root}>
      <StyledTextField
        label="Search"
        variant="outlined"
        value={filterTerm}
        // placeholder={""}
        onChange={(event) => setFilterTerm(event.target.value)}
        InputProps={{
          endAdornment: !!filterTerm && (
            <IconButton aria-label="close" onClick={() => setFilterTerm("")}>
              <CloseIcon />
            </IconButton>
          ),
        }}
      />
      <Box className={classes.tableWrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.siteCell}>Site</TableCell>
              <TableCell className={classes.dateCell}>Date Range</TableCell>
              <TableCell className={classes.downloadCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredFiles.map((file) =>
              file.hidden ? null : (
                <>
                  <TableRow key={file.path} className={classes.stateRow}>
                    <TableCell
                      className={clsx(classes.siteCell, {
                        expandable: !!file.children.length,
                      })}
                      onClick={() =>
                        !!file.children.length && toggleExpanded(file)
                      }
                    >
                      <Box display="flex">
                        <Icon
                          size={"small"}
                          style={{
                            marginRight: 2,
                            marginBottom: 2,
                          }}
                        >
                          {/* rednder <Icon> with null child to maintain vertical alignment */}
                          {!file.children.length ? null : expanded[
                              file.path
                            ] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </Icon>
                        <Typography variant="body1">{file.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell className={classes.dateCell}>
                      {formatDateRangeShort(
                        parseMonthYear(file.earliest_lawsuit),
                        parseMonthYear(file.latest_lawsuit)
                      )}
                    </TableCell>

                    <TableCell className={classes.downloadCell}>
                      {!file.no_download && <DownloadButton file={file} />}
                    </TableCell>
                  </TableRow>
                  {expanded[file.path] &&
                    file.children.map((child) =>
                      child.hidden ? null : (
                        <TableRow className={classes.childRow}>
                          <TableCell className={classes.siteCell}>
                            <Typography key={child.path} variant="body1">
                              {child.name}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.dateCell}></TableCell>
                          <TableCell className={classes.downloadCell}>
                            <DownloadButton file={child} />
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </>
              )
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(FileExplorer);
