module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/node_modules/gatsby-theme-hypercore/src/templates/page.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-relative-images","options":{}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1440,"quality":80,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent"}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"content/assets"}},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-external-links"}],"remarkPlugins":[null],"commonmark":true},
    },{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-hypercore/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"content/pages","assetPath":"content/assets","layouts":{"gallery":"/opt/build/repo/src/arts-and-storytelling/layout.js","about":"/opt/build/repo/src/about-us/layout.js","getData":"/opt/build/repo/src/get-the-data/layout.js","researchIndex":"/opt/build/repo/src/research/index/layout.js","researchPaper":"/opt/build/repo/src/research/paper/layout.js"}},
    },{
      plugin: require('../node_modules/gatsby-theme-hypersite/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"content/pages","assetPath":"content/assets","layouts":{"gallery":"/opt/build/repo/src/arts-and-storytelling/layout.js","about":"/opt/build/repo/src/about-us/layout.js","getData":"/opt/build/repo/src/get-the-data/layout.js","researchIndex":"/opt/build/repo/src/research/index/layout.js","researchPaper":"/opt/build/repo/src/research/paper/layout.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"ycg5tjq"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Debt Collection Lab","short_name":"Debt Collection Lab","start_url":"/","background_color":"#181817","theme_color":"#EA4A2E","display":"standalone","icon":"content/pages/images/favicon-260.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"553bf22262458527bd86761739cf98b3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-05J63KC61T"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    }]
